import styled from "styled-components"
import bannerImage from "assets/images/omnize-images/Group 1755.png"
export const BlogPageWrapper = styled.div `
  .button-blog {
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    button {
      width: 220px;
      border-radius: 5px;
      padding-top: 10px;
      padding-bottom: 10px;
      cursor: pointer;
      border: none;
      color: ${props => props.theme.white};
      transition: all 0.3s ease-in;
      background: rgb(200, 56, 231);
      background: linear-gradient(
        270deg,
        rgba(31, 42, 213, 1) 0%,
        rgba(200, 56, 231, 1) 100%
      );
      &:hover {
        background: rgb(200, 56, 231);
        background: linear-gradient(
          270deg,
          rgba(200, 56, 231, 1) 0%,
          rgba(31, 42, 213, 1) 100%
        );
      }
    }
  }

  .blog-Container {
    background: #210e54;
    margin-bottom: 132px;
  }

  .react-calendar button {
    color: #ffffff;
  }

  .Boxider {
    border-left: 2px solid #00acf1;
  }
  .first-cate {
    margin: 0px;
    margin-top: 75px;
  }

  .margin-head {
    margin: 0px;
  }

  .sidebar-design {
    background: #210e54;
    border: 1px solid #00acf1;
    margin-top: 83px;
    padding-bottom: 100px;
    border-radius: 12px;
  }
  .post-meta-block {
    justify-content: center;
    text-align: center;
  }
  .blog-Text {
    text-align: center;
    font: normal normal 300 28px/42px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 43px;
  }

  .banner__square {
    position: absolute;
    margin-left: -280px;
    top: 170px;
  }

  .banner__circle2 {
    position: absolute;
    top: 242px;
    margin-left: -148px;
  }

  .banner__circle1 {
    position: absolute;
    top: 60px;
    right: -300px;
  }

  @media (min-width: 1200px) {
    .blog-banner {
      width: 1303px;
      max-width: 1300px;
      margin-left: -95px;
      margin-top: -20px;
    }
  }
  .blog-banner {
    position: relative;
    padding: 0px;
  }

  .banner__thumb {
    width: auto;
    height: auto;
  }

  .blog-list-wrapper {
    padding: 50px 0;
  }
  .blog-grid-wrapper {
    padding-bottom: 60px;
  }
  .post-block {
    margin-bottom: 30px;
    border-radius: 3px;
    &:hover {
      .readmore-btn {
        color: #ffffff;
      }
    }
  }
  .post-thumb-block {
    border-radius: 3px 3px 0 0;
    position: relative;
    z-index: 2;
    img {
      border-radius: 3px 3px 0 0;
    }
  }
  .post-content-block {
    border-radius: 0 0 3px 3px;
    // border: 1px solid rgba(190, 171, 223, 0.55);
    padding: 25px 35px;
    transform: translateY(-11px);
    position: relative;
    z-index: 1;
  }
  .post-entry {
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
  }

  .post-meta-block {
    margin-bottom: 10px;
    a {
      margin-right: 10px;
      -webkit-transition: 450ms all;
      transition: 450ms all;
      &:hover {
        color: #ffffff;
      }
    }
    a,
    span {
      color: #ffffff;
      font-size: 14px;
      font-weight: 300;
    }
    span + span {
      margin-left: 10px;
    }
    .divider {
      font-size: 11px;
    }
  }
  .post-title {
    a {
      text-align: center;
      font-size: 21px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 17px;
      display: block;
      color: ${props => props.theme.white};
      -webkit-transition: 450ms all;
      transition: 450ms all;
      // font: normal normal normal 16px/19px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      &:hover {
        color: #beabdf;
      }
    }
  }
  .readmore-btn {
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in;
    svg {
      margin-left: 0px;
      font-size: 27px;
      transition: all 0.3s ease-in;
    }
    &:hover {
      svg {
        margin-left: 8px;
      }
    }
  }

  .pagination-wrapper {
    display: flex;
    margin: 30px 0 60px 0;
    ul,
    .pagination {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: inline-block;

        &.active {
          a {
            opacity: 1;
          }
        }
        a {
          display: block;
          padding: 12px 20px;
          margin-right: 10px;
          border-radius: 5px;
          opacity: 0.6;
        }
      }
    }
  }
  @media only screen and (max-width: 912px) {
    .blog-page-wrapper {
      padding-bottom: 80px;
    }
  }
  @media only screen and (max-width: 568px) {
    .post-thumb-block {
      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 600px) {
    .banner__thumb {
      width: 100% !important;
      height: auto;
      margin-top: 73px;
    }
    .banner__circle1 {
      display: none;
    }

    .blog-Container {
      margin-bottom: 50px;
    }
    .sidebar-design {
      padding: 20px;
      margin-top: 83px;
      padding-bottom: 80px;
    }
  }
`