import React from "react"
import { Link } from "gatsby"

import { IoIosArrowRoundForward } from "react-icons/io"
import Box from "reusecore/Box"
import Text from "reusecore/Text"
import Heading from "reusecore/Heading"
import { Container, Row, Col } from "reusecore/Layout"
import Button from "reusecore/Button"

import bannerImage from "assets/images/omnize-images/Group 1755.png"
import bannerSquare from "assets/images/omnize-images/Group 8.svg"
import bannerCircle1 from "assets/images/omnize-images/Ellipse 1.svg"
import bannerCircle2 from "assets/images/omnize-images/Ellipse 2.svg"

import PageHeader from "../../components/PageHeader"
import Sidebar from "../../components/Omnize-Blog-sidebar"

import data from "assets/data/blog"
import { BlogPageWrapper } from "./blogGrid.style"

const BlogGridItems = () => {
  return (
    <BlogPageWrapper>
      <div className="blog-Container">
        <Container>
          <img
            className="banner__square"
            alt="cryptik banner thumb"
            src={bannerSquare}
          />{" "}
          <img
            className="banner__circle2"
            alt="cryptik banner thumb"
            src={bannerCircle2}
          />{" "}
          <img
            className="banner__circle1"
            alt="cryptik banner thumb"
            src={bannerCircle1}
          />{" "}
          <div className="blog-banner">
            <img
              className="banner__thumb"
              alt="cryptik banner thumb"
              src={bannerImage}
            />{" "}
          </div>{" "}
        </Container>{" "}
      </div>{" "}
      <Box className="blog-page-wrapper">
        <Container>
          <Row>
            <Col className="xs-12 lg-8">
              <Box className="blog-grid-wrapper">
                <Text className="blog-Text"> LATEST NEWS & ARTICLES </Text>{" "}
                <Row>
                  {" "}
                  {/* blog posts */}{" "}
                  {data.posts.map((post, index) => (
                    <Col key={index} className="xs-12 sm-6">
                      <Box className="post-block">
                        <Box className="post-thumb-block">
                          <Link to="/blog-single">
                            <img
                              src={post.thumbnail}
                              alt="cryptik blog image"
                            />
                          </Link>{" "}
                        </Box>{" "}
                        <Box className="post-content-block">
                          <Box className="post-meta-block">
                            <Text as="span"> 1000 views </Text>{" "}
                            {/*
                                                <Link to="/blog-single"> Discussion </Link>{" "}
                                                */}{" "}
                            <Text as="span" className="Boxider">
                              {" "}
                            </Text>{" "}
                            <Text as="span"> 5 comments </Text>{" "}
                            <Text as="span" className="Boxider">
                              {" "}
                            </Text>{" "}
                            <Text as="span"> 15 likes </Text>{" "}
                          </Box>{" "}
                          <Heading as="h2" className="post-title">
                            <Link to="/blog-single"> {post.title} </Link>{" "}
                          </Heading>{" "}
                          {/*
                                              <Text className="post-entry"> {post.body} </Text>{" "}
                                              <Link to="/blog-single" className="readmore-btn">
                                                see more <IoIosArrowRoundForward />
                                              </Link>{" "}
                                            */}{" "}
                        </Box>{" "}
                      </Box>{" "}
                    </Col>
                  ))}{" "}
                  {/* blog posts end*/}{" "}
                  <div className="button-blog">
                    {" "}
                    <Button> LOAD MORE </Button>
                  </div>{" "}
                </Row>{" "}
              </Box>{" "}
            </Col>{" "}
            <Col className="xs-12 sm-4">
              <Sidebar />
            </Col>{" "}
          </Row>{" "}
        </Container>{" "}
      </Box>{" "}
    </BlogPageWrapper>
  )
}

export default BlogGridItems
